import { StoreAction } from "../../@core/constants/action";

const initialState = { chatBotList: [] };

export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.SET_CHAT_BOT_FLOW_LIST:
      return { ...state, chatBotList: action.payload };
    default:
      return state;
  }
}
