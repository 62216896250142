import { StoreAction } from "../../@core/constants/action";

const initialState = {
  quickReplyList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.SET_QUICK_REPLY_MESSAGE_LIST:
      return { ...state, quickReplyList: action.payload };
    default:
      return state;
  }
}
