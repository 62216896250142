import { StoreAction } from "../../@core/constants/action";

const initialState = {
  device_id: null,
  qr_loading: false,
  qr_code: null,
  device_modal: false,
  deviceModalType: null, // 1 - Add New Device modal, 2 - Reconnect device modal
  requestedDevice: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.SET_QR_LOADING: {
      return { ...state, qr_loading: action.payload };
    }
    case StoreAction.SET_DEVICE_ID: {
      return { ...state, device_id: action.payload };
    }
    case StoreAction.QR_CODE: {
      return { ...state, qr_code: action.payload };
    }
    case StoreAction.SET_DEVICE_MODAL: {
      return { ...state, device_modal: action.payload };
    }
    case StoreAction.SET_DEVICE_MODAL_TYPE: {
      return { ...state, deviceModalType: action.payload };
    }
    case StoreAction.REQUESTED_DEVICE: {
      return {
        ...state, requestedDevice: action.payload
      };
    }
    default:
      return state;
  }
}
