import { StoreAction } from "../../@core/constants/action";

const initialState = { customerList: [] };

export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.SET_CUSTOMER_LIST:
      return { ...state, customerList: action.payload };
    default:
      return state;
  }
}
